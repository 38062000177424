import {default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";

class CompanyService {
  getCoachesFromCompany() {
    return apiAxiosInstance.get(apiHelper.coachesFromCompanyEndpoint);
  }
}

export default new CompanyService();

<template>
  <Title title="Choix du coach" :showBack="true" :showIcon="true"/>
  <div class="coach-container" v-for="coach in coaches" :key="coach.identifier">
    <div class="coach-card">
      <div @click="this.selectedVideo = coach" class="avatar">
        <img v-if="this.generateAvatarSrcPath(coach.avatar.absolute_url)"
             :src="this.generateAvatarSrcPath(coach.avatar.absolute_url)" alt="coach-avatar">
        <img v-else src="@/assets/images/avatar.png" alt="coach-avatar">
        <span v-if="coach.youtube_url" class="player"></span>
        <div class="name-container">
          <span>{{ coach.first_name }}</span><br>
          <span>{{ coach.last_name }}</span>
        </div>
        <div v-if="coach.is_internal" class="internal-coach">
          <img v-if="this.generateAvatarSrcPath(coach.companies[0].company_image.absolute_url)"
               :src="this.generateAvatarSrcPath(coach.companies[0].company_image.absolute_url)" alt="company-image">
          <span class="">Coach interne</span>
        </div>
      </div>
      <div class="coach-description">
        <p class="label">A savoir:</p>
        <p class="desc">{{ coach.description }}</p>
        <p v-if="coach.coach_skills.length" class="label">Domaines d'expertises:</p>
        <div v-if="coach.coach_skills.length" class="coach-skills-container">
          <div>
            <li v-for="(skill, index) in filteredSkillsFirstRow(coach.coach_skills)" :key="index">
              {{ skill.name }}
            </li>
          </div>
          <div class="second-row">
            <li v-for="(skill, index) in filteredSkillsSecondRow(coach.coach_skills)" :key="index">
              {{ skill.name }}
            </li>
          </div>
        </div>
        <Transition v-if="coach.youtube_url" name="accordion">
          <div v-show="this.selectedVideo === coach" class="iframe-container">
            <iframe :src="this.youtubeUrl + coach.youtube_url" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            <span class="closed-button" @click.prevent="this.selectedVideo = null"></span>
          </div>
        </Transition>
      </div>
      <div class="selected-row">
        <a :style="{background: selectedCoach === coach ? 'grey' : '#FFB7A0', color: selectedCoach === coach ? 'white' : '#004240'}"
           @click="this.selectedCoach = coach" class="button-cta">
          <span v-if="selectedCoach === coach">Choisi (1)</span>
          <span v-else>Choisir</span>
        </a>
      </div>
    </div>
  </div>
  <div class="validate-button"><a @click.prevent="assignCoach" class="button-cta">Valider</a></div>
</template>

<script>
import coachService from '@/services/coach.service';
import boostPackService from '@/services/boostPack.service';
import Title from "../../components/General/Title";
import Toastify from "toastify-js";

export default {
  name: "BoostPackCoachSelection",
  components: {Title},
  data() {
    return {
      coaches: [],
      boostPack: null,
      selectedCoach: null,
      selectedVideo: null,
      youtubeUrl: 'https://www.youtube.com/embed/',
    };
  },
  created() {
    boostPackService.getCurrentBoostPack().then((response) => {
      this.boostPack = response.data.data.boost_pack
    })
    coachService.getCoachesFromCompany().then((response) => {
      this.coaches = response.data.data.coaches;
    })
  },
  methods: {
    generateAvatarSrcPath(path) {
      if (!path) {
        return null;
      }
      return path.replace("v1/", "");
    },
    filteredSkillsFirstRow(array) {
      return array.slice(0, 3)
    },
    filteredSkillsSecondRow(array) {
      return array.slice(3, 6)
    },
    assignCoach() {
      if (this.selectedCoach) {
        boostPackService.assignCoachCurrent(this.selectedCoach.id)
            .then(() => {
              if (this.boostPack.boost_consumption !== 0) {
                this.$router.push({name: 'BoostPackDashboard'})
              } else {
                this.$router.push({name: 'BoostPackCriterion'})
              }
              Toastify({
                text: "Coach modifié",
                className: "success",
              }).showToast();
            });
      }
    }
  }
}
</script>

<style scoped>
</style>
